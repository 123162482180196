import {PatientItem} from "../Patient/PatientItem";
import {QuestionnaireService} from "../../services/QuestionnaireService";
import {IAnswer} from "../IAnswer";
import {NitTools} from "../NursitTools";
import {translations} from "../translations";
import {Tools} from "./Tools";
import * as Fhir from "./Fhir";
import {Questionnaire} from "./Fhir";
import {RuntimeInfo} from "../RuntimeInfo";
import {PatientService} from "../../services/PatientService";
import * as environment from "../../../../config/environment.json";
import {ConfigService} from "../../services/ConfigService";
import {IFormSetting} from "../IFormSettings";
import {fhirEnums} from "../fhir-enums";
import {FhirService} from "../../services/FhirService";
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;

const moment = require("moment");

export class QuestionnaireResponse {
    public static LinkResponsesMutual(patient : PatientItem, response1 : any, response2 : any) {
        this.SetAttachedResponse(patient, response1, response2);
        this.SetAttachedResponse(patient, response2, response1);
    }

    public static SetAttachedResponse(patient: PatientItem, targetResponse: any, responseToLink: any) : boolean {
        if (!targetResponse || !responseToLink || !responseToLink.questionnaire) return;
        let isValid = true;
        if (targetResponse.subject?.reference.indexOf('/' + patient.id) === -1)
        {
            console.warn('targetResponse is not for the given patient!', targetResponse, patient);
            isValid = false;
        }

        if (responseToLink.subject?.reference.indexOf('/' + patient.id) === -1) {
            console.warn('responseToLink is not for the given patient!', responseToLink, patient);
            isValid = false;
        }

        if (responseToLink.subject?.reference !== targetResponse.subject?.reference) {
            console.warn('responseToLink and targetResponse are not for the same patient', responseToLink, targetResponse, patient);
            isValid = false;
        }

        const responseQuestionnaire = QuestionnaireService.GetQuestionnaireDirect(responseToLink.questionnaire);
        if (!responseQuestionnaire) {
            console.warn(`Questionnaire to link not found! Used for search: `, responseToLink.questionnaire);
            isValid = false;
        }

        if (!isValid)
            return false;

        if (!targetResponse.extension) targetResponse.extension = [];

        if (targetResponse.extension) {
            targetResponse.extension = targetResponse.extension.filter(o => o.url.indexOf(`StructureDefinition/${responseQuestionnaire.name}`) === -1
                && o.url.indexOf(`questionnaireLink/${responseQuestionnaire.name}`) === -1);
        }

        const linkIdCleaned = `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/questionnaireLink/${responseQuestionnaire.name}`;
        let linkExtension = Fhir.Tools.GetOrCreateExtension(targetResponse, linkIdCleaned, true);
        linkExtension.valueReference = {
            reference: `QuestionnaireResponse/${responseToLink.id}`
        };

        // correct the extensions with double http in the url (wherever it comes from)
        for (const extension of targetResponse.extension.filter(o => {
            /http:\/\/.*http:\/\//.test(`${FhirService.Endpoint}/http:`)
        })) {
            const [dummy, wrong, correct] = extension.url.split('http:');
            extension.url = "http:" + correct;
        }

        if (patient) {
            if (!PatientService.AddQuestionnaireResponse(patient, targetResponse, true)) {
                return false;
            }
        }

        return true;
    }

    /***
     * Searches for a linked QuestionnaireResponse. First by the (correct) Extension with the QuestionnaireName from the route-config, then by the given alternate Name and finally just the latest existing response
     * @param patient the patient to search the Response for
     * @param route the name of the route, specified in the configuration file (setup.json or war-override)
     * @param alternateName the alternate name to seek for
     * @param linkSource the response where the targeted response should have a link in. Default: PatientItem.LastLoadedPatient.latestAssessment
     * @param returnLatestExistingResponse indicates whether latest matching response that exists in the patient should be returned or not. Default: true
     */
    public static async SeekForAttachedResponse(patient: PatientItem, route: string, alternateName: string,
                                                linkSource: any = PatientItem.LastLoadedPatient.latestAssessment,
                                                returnLatestExistingResponse: boolean = true) {
        let result: any;
        let form: IFormSetting;
        let cfg = await ConfigService.LoadConfigOverride(patient?.ward, patient);

        if (!linkSource)
            linkSource = patient.latestAssessment;

        if (!linkSource) {
            const formAssessment = cfg.forms.find(o => o.route && o.route.toUpperCase() === 'ASSESSMENT');
            if (formAssessment) {
                linkSource = QuestionnaireService.GetLatestResponseOfName(patient, formAssessment.questionnaireName, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
            }
        }

        if (cfg && cfg.forms) {
            form = cfg.forms.find(o => o.route && o.route.toUpperCase() === route.toUpperCase());
            if (form) {
                result = await Fhir.QuestionnaireResponse.GetAttachedResponse(patient, linkSource, form.questionnaireName);
            }
        }

        if (!result) {
            if (linkSource && linkSource.extension) {
                const links: any[] = linkSource.extension.filter(o => o.url && o.url.toUpperCase().endsWith('/' + alternateName.toUpperCase()));
                if (links) {
                    const items = links.map(o => o.valueString || o.valueId || o.valueReference?.reference);
                    const responses: any[] = [];

                    for (const item of items) {
                        responses.push(await QuestionnaireService.GetResponseById(patient, item));
                    }

                    // sort responses by date, latest first
                    responses.sort((a, b) => {
                        if (a.authored && !b.authored) return 1;
                        if (!a.authored && b.authored) return -1;
                        if (!b.authored && !a.authored) return 0;
                        const date1 = moment(a.authored).toDate().valueOf();
                        const date2 = moment(b.authored).toDate().valueOf();

                        return date2 - date1;
                    })

                    result = responses[0];
                }
            }
        }

        if (form && !result && returnLatestExistingResponse) {
            result = QuestionnaireService.GetLatestResponseOfName(patient, form.questionnaireName, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.inProgress]);
        }

        return result;
    }

    /**
     * Get the attached response questionnaireResponse from the given assessment, identified by the linkId
     * @param patient the PatientItem which is the current context
     * @param assessment the Assessment to get the linked responses for
     * @param questionnaireName the linkId to return. Should be BarthelIndex|BarthelIndexEx|FIM|Diagnosis
     */
    public static async GetAttachedResponse(patient: PatientItem, assessment: any, questionnaireName: string): Promise<any> {
        let response: any;

        if (assessment && assessment.extension) {
            const linkIdCleaned = `questionnaireLink/${questionnaireName}`; //questionnaireName.indexOf('/') > -1 ? questionnaireName : 'structureDefinition/questionnaireLink/' + questionnaireName;
            let linkExtension = Fhir.Tools.GetOrCreateExtension(assessment, linkIdCleaned, false);
            if (!linkExtension) {
                let nameAlternate = questionnaireName.replace('CareIT_', '')
                    .replace('CareIt_', '')
                    .replace('CareIT', '')
                    .replace('CareIt', '')

                linkExtension = assessment.extension.find(o => o.url.endsWith('/' + nameAlternate.toUpperCase()));
            }

            if (linkExtension && (linkExtension.valueString || linkExtension.valueReference)) {
                let linkedId = undefined;
                if (linkExtension.valueReference && linkExtension.valueReference.reference)
                    linkedId = linkExtension.valueReference.reference;
                // response = await QuestionnaireService.GetResponseById(patient, linkExtension.valueReference.reference);
                else
                    linkedId = linkExtension.valueString;
                response = QuestionnaireService.GetResponseByIdDirect(patient, linkedId);

                if (!response)
                    response = await QuestionnaireService.GetResponseById(patient, linkedId);
            }
        }

        // load the attached document by the questionnaire fields if not found in extensions
        if (!response) {
            let responseItem = this.GetResponseItemByLinkId(assessment, questionnaireName, false);
            let itemValue: string = undefined;
            if (responseItem) {
                if (responseItem.answer) {
                    let answer = responseItem.answer[0];
                    if (answer.valueReference) {
                        itemValue = answer.valueReference.reference;
                    } else if (answer.valueCoding) {
                        itemValue = answer.valueCoding.code;
                    } else if (answer.valueString) {
                        itemValue = answer.valueString;
                    } else if (answer.valueUri) {
                        itemValue = answer.valueUri;
                    }

                    if (itemValue && itemValue.indexOf('/') > -1) {
                        itemValue = itemValue.split('/')[1];
                    }
                }

                if (itemValue) {
                    response = QuestionnaireService.GetResponseByIdDirect(patient, itemValue);

                    if (!response)
                        response = await QuestionnaireService.GetResponseById(patient, itemValue)
                }
            }
        }

        if (response)
            PatientService.AddQuestionnaireResponse(patient, response, true);

        return response;
    }

    public static GetAttachedResponseDirect(patient: PatientItem, assessment: any, questionnaireName: string): any {
        let response: any;

        if (assessment.subject?.reference?.indexOf(patient.id) === -1)
            debugger;

        if (assessment && assessment.extension) {
            const linkIdCleaned = `questionnaireLink/${questionnaireName}`; //questionnaireName.indexOf('/') > -1 ? questionnaireName : 'structureDefinition/questionnaireLink/' + questionnaireName;
            let linkExtension = Fhir.Tools.GetOrCreateExtension(assessment, linkIdCleaned, false);
            if (!linkExtension) {
                let nameAlternate = questionnaireName.replace('CareIT_', '')
                    .replace('CareIt_', '')
                    .replace('CareIT', '')
                    .replace('CareIt', '')

                linkExtension = assessment.extension.find(o => o.url.endsWith('/' + nameAlternate.toUpperCase()));
            }

            if (linkExtension && (linkExtension.valueString || linkExtension.valueReference)) {
                let linkedId = undefined;
                if (linkExtension.valueReference && linkExtension.valueReference.reference)
                    linkedId = linkExtension.valueReference.reference;
                // response = await QuestionnaireService.GetResponseById(patient, linkExtension.valueReference.reference);
                else
                    linkedId = linkExtension.valueString;

                response = QuestionnaireService.GetResponseByIdDirect(patient, linkedId);
            }
        }

        // load the attached document by the questionnaire fields if not found in extensions
        /* DEPRECATED!
        if (!response) {
            let responseItem = this.GetResponseItemByLinkId(assessment, questionnaireName, false);
            let itemValue: string = undefined;
            if (responseItem) {
                if (responseItem.answer) {
                    let answer = responseItem.answer[0];
                    if (answer.valueReference) {
                        itemValue = answer.valueReference.reference;
                    } else if (answer.valueCoding) {
                        itemValue = answer.valueCoding.code;
                    } else if (answer.valueString) {
                        itemValue = answer.valueString;
                    } else if (answer.valueUri) {
                        itemValue = answer.valueUri;
                    }

                    if (itemValue && itemValue.indexOf('/') > -1) {
                        itemValue = itemValue.split('/')[1];
                    }
                }

                if (itemValue) {
                    response = QuestionnaireService.GetResponseByIdDirect(patient, itemValue);
                }
            }
        }
        */
        return response;
    }

    /** Check if the Item-values between the QuestionnaireResponses responseA and responseB differ.
     * @param  responseA the first QuestionnaireResponse for compare
     * @param  responseB the second QuestionnaireResponse for compare
     * @return a boolean indicating whether the item values differ
     **/
    public static ResponseValuesDiffer(responseA: any, responseB: any): boolean {
        return Tools.ResponseValuesDiffer(responseA, responseB);
    }

    public static GetItemAnswer(answer: IAnswer, defaultValue?: any): any {
        return this.GetResponseAnswerValue(answer) || defaultValue;
        /*         if (!answer || !answer.valueCoding || !answer.valueCoding.code) return defaultValue;
        
                return answer.valueCoding.code; */
    }

    private static findResponseItem(item: any, linkid: string): any {
        if (!item || !item.linkId || !linkid) return undefined;
        if (item.linkId.toUpperCase() === linkid.toUpperCase()) return item;

        if (item.item && item.item.length > 0) {
            for (let i = 0; i < item.item.length; i++) {
                let itm = this.findResponseItem(item.item[i], linkid);
                if (itm) return itm;
            }
        }

        return undefined;
    }

    /**
     * gets the first answer from the given item as number
     * @param item an instance of type any
     * @param defaultValue the default value to return if not able to parse. default if not set is 0
     */
    public static GetKeyValueAsNumber(item: any, defaultValue: number = 0): number {
        if (typeof item === "string") {
            let s = String(item);
            if (s.indexOf('_') > -1) {
                let sa = s.split('_');
                s = sa[sa.length - 1];
            }

            return parseInt(s);
        } else if (typeof item === "number") {
            return parseInt(String(item)); // cur decimals off
        }

        if (item.answer && item.answer[0] && typeof item.answer[0].valueInteger !== "undefined") {
            return item.answer[0].valueInteger;
        }

        let value = this.GetResponseItemValue(item);
        if (typeof value === "number") {
            return value;
        } else if (typeof value === "string") {
            let s = String(value);
            if (s.indexOf('_') > -1) {
                let sa = s.split('_');
                s = sa[sa.length - 1];
            }

            return parseInt(s);
        }

        /* if (!value) {
            console.warn(`[Fhir] - GetKeyValueAsNumber: No value for "${item.linkId}" in `, item);
        } */

        return defaultValue;
    }

    public static GetResponseItemByLinkId(questionnaireResponse, linkId: string, createIfNotExists: boolean = false): any {
        const fixR4 = function(item) {
            if (item && !item.option && item["answerOption"])
                item.option = item["answerOption"];

            return item;
        }

        if (!questionnaireResponse) return undefined;
        let response = questionnaireResponse;
        if (response && !response.item) response.item = [];
        //let questionnaire = response && response.questionnaire && response.questionnaire.reference ? QuestionnaireService.GetQuestionnaireByIdDirect(response.questionnaire.reference) : undefined;

        try {
            if (!response || !linkId) return undefined;
            if (!response.item) {
                response.item = [];
            }

            let result = response.item.filter(o => typeof o !== "undefined" && o.linkId)
                                            .find(o => o.linkId.toUpperCase() === linkId.toUpperCase());
            if (typeof result !== "undefined") return fixR4(result);

            for (let i = 0; i < response.item.length; i++) {
                result = this.findResponseItem(response.item[i], linkId);
                if (typeof result !== 'undefined') {
                    return fixR4(result);
                }
            }

            if (!createIfNotExists) return undefined;
            result = undefined;

            return result;
        } catch (e) {
            console.warn(e.message || JSON.stringify(e));
            return undefined;
        }
    }

    public static GetOptionText = (linkIdValue: string, item: any, defaultValue? : string) => {
        let result = defaultValue;
        const fixR4 = function(item) {
            if (item && !item.option && item["answerOption"])
                item.option = item["answerOption"];

            return item;
        }
        item = fixR4(item);

        if (linkIdValue && item?.option) {
            const option = item.option.find(o => o.valueCoding && o.valueCoding.code === linkIdValue);
            if (typeof option?.valueCoding?.display === "string")
                result = option.valueCoding.display;
        }

        return result;
    }


    public static GetOrdinalValue = (linkIdValue: string, item: any, defaultValue : number = NaN): number => {
        const fixR4 = function(item) {
            if (item && !item.option && item["answerOption"])
                item.option = item["answerOption"];

            return item;
        }
        item = fixR4(item);

        if (item && item.option) {
            const option = item.option.find(o => o.valueCoding && o.valueCoding.code === linkIdValue);
            if (option && option.extension) {
                const ordExtension = option.extension.find(o => o.url.endsWith('/questionnaire-ordinalValue'));
                if (ordExtension) {
                    if (typeof ordExtension.valueDecimal === 'number')
                        return Number(ordExtension.valueDecimal);
                    else if (typeof ordExtension.valueInteger === 'number')
                        return Number(ordExtension.valueInteger);
                }
            }
        }

        return defaultValue;
    }

    public static GetKeyValueAsInteger(str: string | number | boolean): number {
        if (typeof str === "undefined") return NaN;
        if (typeof str === "number") return <number>str;
        if (typeof str === "boolean") return <boolean>str ? 1 : 0;
        if (typeof str === "string") {
            if (str.toUpperCase() === "TRUE") return 1;
            else if (str.toUpperCase() === "FALSE") return 0;
        }

        try {
            if (str.indexOf('_') > -1) {
                let arr = str.split('_');
                str = arr[arr.length - 1];
            }

            if (isNaN(parseInt(str)) && str.indexOf('-') > 0) {
                let arr = str.split('-');
                str = arr[arr.length - 1];
            }

            if (str === 'null' || str === 'nil') return NaN;
            let result = parseInt(str);

            return result;
        } catch (e) {
            console.warn(e.message);
            return NaN;
        }
    }

    public static GetResponseItemValueInt(item: any, defaultValue: number = undefined): number {
        if (!item) return defaultValue;
        let s = this.GetResponseItemValue(item);
        if (!s) return defaultValue;

        if (typeof s === "number")
            return s;

        try {
            return this.GetKeyValueAsInteger(s);
        } catch (e) {
            console.warn(e.message);
            return defaultValue;
        }
    }

    public static GetResponseAnswerDisplay(answer): any {
        let result: any = undefined;
        if (typeof answer !== "undefined") {
            if (typeof answer.value !== "undefined") result = String(answer.value);
            else if (typeof answer.valueCode !== "undefined") result = String(answer.valueCode);
            else if (answer.valueCoding && answer.valueCoding.code) result = answer.valueCoding.display;
            else if (answer.valueCodeableConcept && answer.valueCodeableConcept.text) result = answer.valueCodeableConcept.text;
            else if (answer.valueAddress && answer.valueAddress.text) result = answer.valueAddress.text;
            else if (answer.valueAnnotation && answer.valueAnnotation.text) result = answer.valueAnnotation.text;
            else if (answer.valueAttachment && answer.valueAttachment.title) result = answer.valueAttachment.title;
            else if (typeof answer.valueBase64Binary !== "undefined") result = answer.text;
            else if (typeof answer.valueBoolean !== "undefined") result = translations.translate(NitTools.ParseBool(answer.valueBoolean) ? 'yes' : 'no');
            else if (answer.valueContactPoint && answer.valueContactPoint.value) result = answer.valueContactPoint.value;
            else if (typeof answer.valueDate !== "undefined") result = moment(answer.valueDate).format(RuntimeInfo.DateFormat)
            else if (typeof answer.valueDateTime !== "undefined") result = moment(answer.valueDateTime).from(RuntimeInfo.DateTimeFormat)
            else if (typeof answer.valueTime !== "undefined") result = moment(answer.valueTime).format(RuntimeInfo.TimeFormat)
            else if (typeof answer.valueInstant !== "undefined") result = moment(answer.valueInstant).format(RuntimeInfo.DateTimeFormat)
            else if (typeof answer.valueDecimal !== "undefined") result = Number(answer.valueDecimal).toLocaleString()
            else if (answer.valueHumanName && answer.valueHumanName.text) result = answer.valueHumanName.text;
            else if (typeof answer.valueId !== "undefined") result = answer.valueId;
            else if (typeof answer.valueIdentifier !== "undefined") result = answer.valueIdentifier.value;
            else if (typeof answer.valueInteger !== "undefined") result = Number(answer.valueInteger).toFixed(0).toString();
            else if (typeof answer.valueMarkdown !== "undefined") result = answer.valueMarkdown;
            else if (typeof answer.valueMeta !== "undefined") result = answer.valueMeta;
            else if (typeof answer.valueOid !== "undefined") result = answer.valueOid;
            else if (typeof answer.valuePeriod !== "undefined") result = moment(answer.valuePeriod.start).format(translations.translate("date_time_format_short")) + " - " + moment(answer.valuePeriod.end).format(translations.translate("date_time_format_short"));
            else if (typeof answer.valuePositiveInt !== "undefined") {
                let int = parseInt(String(answer.valuePositiveInt));
                if (int < 0) int *= -1;
                result = int.toString();
            } else if (answer.valueRatio) result = "valueRation not supported";
            else if (answer.valueReference) result = answer.valueReference.display;
            else if (answer.valueUuid) result = answer.valueUuid;
            else if (answer.valueUri) result = answer.valueUri;
            else if (typeof answer.valueUnsignedInt !== "undefined") result = Number(answer.valueUnsignedInt).toFixed(0).toString();
            else if (typeof answer.valueString !== "undefined") result = answer.valueString;
            else if (answer.valueTiming) result = moment(answer.valueTiming.event).format("date_time_format_short");
            else if (answer.valueSignature) result = answer.valueSignature.whoReference && answer.valueSignature.whoReference.display ? answer.valueSignature.whoReference.display : "Signature";
            else if (answer.valueRange) result = `${answer.valueRange.low.value}${answer.valueRange.low.unit} - ${answer.valueRange.high.value}${answer.valueRange.high.unit}`;
            else if (answer.valueSampledData) result = "Sampled Data";
            else if (typeof answer.valueQuantity !== "undefined" && typeof (answer.valueQuantity.value || answer.valueQuantity.code) !== "undefined")
                result = String(answer.valueQuantity.code || answer.valueQuantity.value);
        }

        return result;
    }

    public static GetResponseAnswerValue(answer): any {
        let result: any = undefined;
        if (typeof answer !== "undefined") {
            if (typeof answer.value !== "undefined") result = String(answer.value);
            else if (typeof answer.valueCode !== "undefined") result = String(answer.valueCode);
            else if (answer.valueCoding && answer.valueCoding.code) result = answer.valueCoding.code;
            else if (answer.valueCodeableConcept && answer.valueCodeableConcept.text) result = answer.valueCodeableConcept.text;
            else if (answer.valueAddress && answer.valueAddress.text) result = answer.valueAddress.text;
            else if (answer.valueAnnotation && answer.valueAnnotation.text) result = answer.valueAnnotation.text;
            else if (answer.valueAttachment && answer.valueAttachment.title) result = answer.valueAttachment.title;
            else if (typeof answer.valueBase64Binary !== "undefined") result = answer.valueBase64Binary;
            else if (typeof answer.valueBoolean !== "undefined") result = NitTools.ParseBool(answer.valueBoolean);
            else if (answer.valueContactPoint && answer.valueContactPoint.value) result = answer.valueContactPoint.value;
            else if (typeof answer.valueDate !== "undefined") result = moment(answer.valueDate).toJSON();
            else if (typeof answer.valueDateTime !== "undefined") result = moment(answer.valueDateTime).toJSON();
            else if (typeof answer.valueTime !== "undefined") result = answer.valueTime;
            else if (typeof answer.valueInstant !== "undefined") result = moment(answer.valueInstant).toJSON();
            else if (typeof answer.valueDecimal !== "undefined") result = Number(answer.valueDecimal);
            else if (answer.valueHumanName && answer.valueHumanName.text) result = answer.valueHumanName.text;
            else if (typeof answer.valueId !== "undefined") result = answer.valueId;
            else if (typeof answer.valueIdentifier !== "undefined") result = answer.valueIdentifier.value;
            else if (typeof answer.valueInteger !== "undefined") result = Number(answer.valueInteger).toFixed(0);
            else if (typeof answer.valueMarkdown !== "undefined") result = answer.valueMarkdown;
            else if (typeof answer.valueMeta !== "undefined") result = answer.valueMeta;
            else if (typeof answer.valueOid !== "undefined") result = answer.valueOid;
            else if (typeof answer.valuePeriod !== "undefined") result = moment(answer.valuePeriod.start).format(translations.translate("date_time_format_short")) + " - " + moment(answer.valuePeriod.end).format(translations.translate("date_time_format_short"));
            else if (typeof answer.valuePositiveInt !== "undefined") {
                let int = parseInt(String(answer.valuePositiveInt));
                if (int < 0) int *= -1;
                result = int;
            } else if (answer.valueRatio) result = "valueRation not supported";
            else if (answer.valueReference) result = answer.valueReference.reference;
            else if (answer.valueUuid) result = answer.valueUuid;
            else if (answer.valueUri) result = answer.valueUri;
            else if (typeof answer.valueUnsignedInt !== "undefined") result = Number(answer.valueUnsignedInt).toFixed(0);
            else if (typeof answer.valueString !== "undefined") result = answer.valueString;
            else if (answer.valueTiming) result = moment(answer.valueTiming.event).format("date_time_format_short");
            else if (answer.valueSignature) result = answer.valueSignature.whoReference && answer.valueSignature.whoReference.display ? answer.valueSignature.whoReference.display : "Signature";
            else if (answer.valueRange) result = `${answer.valueRange.low.value}${answer.valueRange.low.unit} - ${answer.valueRange.high.value}${answer.valueRange.high.unit}`;
            else if (answer.valueSampledData) result = "Sampled Data";
            else if (typeof answer.valueQuantity !== "undefined" && typeof (answer.valueQuantity.value || answer.valueQuantity.code) !== "undefined")
                result = String(answer.valueQuantity.code || answer.valueQuantity.value);

        }

        return result;
    }

    public static GetResponseItemValueIntByLinkId(response: any, linkId: string, defaultValue = undefined) {
        let item = QuestionnaireResponse.GetResponseItemByLinkId(response, linkId, false);
        return QuestionnaireResponse.GetResponseItemValueInt(item, defaultValue);
    }

    public static GetResponseItemValueByLinkId(response: any, linkId: string, defaultValue = undefined) {
        if (!response || !linkId)
            return defaultValue;

        let item = QuestionnaireResponse.GetResponseItemByLinkId(response, linkId, false);
        return QuestionnaireResponse.GetResponseItemValue(item, defaultValue);
    }

    public static GetResponseItemValue(responseItem, defaultValue = undefined) {
        if (!responseItem || !responseItem.answer || responseItem.answer.length === 0 || typeof responseItem.answer[0] === "undefined") return defaultValue;
        let result: any = this.GetResponseAnswerValue(responseItem.answer[0]);
        if (typeof result === "undefined") result = defaultValue;

        return result;
    }

    public static GetResponseItemDisplay(item: any, defaultValue = undefined) {
        if (!item || !item.answer || item.answer.length === 0 || !item.answer[0]) return defaultValue;
        let arr = [];
        item.answer.forEach(a => {
            let display = this.GetResponseAnswerDisplay(a);
            if (display) {
                arr.push(display);
            }
        })

        let result = arr.join(', ').trim();

        return result;
    }

    private static _setDefaultResponseValues(response: any|any, questionnaireItem: any|any, previousResponse?: any|any) {
        if (questionnaireItem) {
            let responseItem = QuestionnaireResponse.GetResponseItemByLinkId(response, questionnaireItem.linkId, true);
            let currentValue = this.GetResponseItemValue(responseItem);

            // only set default value, if the responseItem has no value defined to avoid overwriting
            if (!currentValue) {
                let initial: any = undefined;

                if (FhirService.FhirVersion <= 3) {
                    // get the initial value from the previous response
                    if (previousResponse) {
                        initial = QuestionnaireResponse.GetResponseItemValue(questionnaireItem, previousResponse);
                    }

                    // if no previous value found, get the initial value from the questionnaireItem
                    if (typeof initial === "undefined") {
                        initial = Questionnaire.GetInitialValue(questionnaireItem, response);
                    }

                    // set the initial value as coding (is corrected later by FixResponseAnswers()) to the response
                    if (typeof initial !== "undefined") {
                        if (responseItem) {
                            if (!NitTools.IsArray(initial)) {
                                QuestionnaireResponse.SetResponseItemCoding(responseItem, Questionnaire.GetInitialValue(questionnaireItem, previousResponse ? previousResponse : response));
                                if (questionnaireItem.text) responseItem.text = questionnaireItem.text;
                            } else {
                                responseItem.answer = initial;
                            }
                        }
                    }
                } else if (FhirService.FhirVersion >= 4) {
                    if (previousResponse) {
                        const pAnswers = QuestionnaireResponse.GetResponseItemValueByLinkId(previousResponse, questionnaireItem.linkId)?.answer;
                        if (NitTools.IsArray(pAnswers)) {
                            initial = NitTools.Clone(pAnswers);
                            console.log("Using Previous Value");
                        }
                    }

                    if (!initial || initial?.length === 0 && NitTools.IsArray(questionnaireItem.initial)) {
                        initial = NitTools.Clone(questionnaireItem.initial);
                    }

                    responseItem.answer = initial;
                }
            }

            // do the same for all child nodes
            if (questionnaireItem.item) {
                questionnaireItem.item.forEach(item => this._setDefaultResponseValues(response, item, previousResponse));
            }
        }
    }

    /** get the default values from the questionnaire or from the previous response and store them into the given QuestionnaireResponse */
    public static SetDefaultValues(targetResponse, previousResponse?) {
        // get the questionnaire
        let questionnaire = QuestionnaireService.GetQuestionnaireDirect(targetResponse.questionnaire);

        if (questionnaire) {
            // ensure a correct structure
            Questionnaire.EnsureStructuredResponse(questionnaire, targetResponse);

            for (const item of questionnaire.item) {
                this._setDefaultResponseValues(targetResponse, item, previousResponse);
            }

            let calculatedFields = Questionnaire.GetCalculatedFields(questionnaire);
            if (calculatedFields) {
                for (const field of calculatedFields) {
                    let val = undefined;
                    try {
                        val = Tools.CalculateField(field, questionnaire, targetResponse);
                    } catch (e) {
                        if (ConfigService.Debug)
                            console.warn(e);
                        val = undefined;
                    }

                    if (typeof val !== "undefined") {
                        // store as coding. Is corrected later
                        // if (ConfigService.Debug) console.debug("In SetDefaultValues, calculated field " + field.linkId + " with value " + String(val));
                        QuestionnaireResponse.SetResponseItemCodingByLinkId(targetResponse, field.linkId, val);
                    }
                }
            }

            // move the coding value to the correct place
            Questionnaire.FixResponseAnswers(questionnaire, targetResponse);

            // response.item = this.SetDefaultValuesFix(questionnaire, response).item;
        } else {
            let msg = `Questionnaire not found!`;
            if (targetResponse)
                msg += `\nReferenced by QuestionnaireResponse/${targetResponse.id}`;

            console.warn(msg, targetResponse);
        }

        return targetResponse;
        ///////////
    }

    public static SetResponseItemIntByLinkId(response: any, linkId: string, value: number) {
        let item = QuestionnaireResponse.GetResponseItemByLinkId(response, linkId, true);
        if (item) {
            item.answer = [{valueInteger: value}];
        }
    }

    public static SetResponseItemDecimalByLinkId(response: any, linkId: string, value: number) {
        let item = QuestionnaireResponse.GetResponseItemByLinkId(response, linkId, true);
        if (item) {
            item.answer = [{valueDecimal: value}];
        }
    }

    public static SetResponseItemStringByLinkId(response: any, linkId: string, value: string) {
        let item = QuestionnaireResponse.GetResponseItemByLinkId(response, linkId, true);
        if (item) {
            item.answer = [{valueString: value}];
        }
    }

    public static SetResponseItemString(item: any, value: string) {
        if (item) {
            item.answer = [{valueString: value}];
        }
    }

    public static SetResponseItemCodingByLinkId(response, linkId: string, value: string[] | string | number | boolean, display?: string, system?: string) {
        let item = QuestionnaireResponse.GetResponseItemByLinkId(response, linkId, true);
        QuestionnaireResponse.SetResponseItemCoding(item, value, display, system);
    }

    public static SetResponseItemCoding(item: any, value: string[] | string | number | boolean, display?: string, system?: string) {
        if (typeof item === "undefined") return;

        if (typeof value === "undefined") {
            delete item.answer;
            return;
        }

        item.answer = [];

        if (NitTools.IsArray(value)) {
            (<string[]>value).forEach((val: string) => {
                let answer = {
                    valueCoding: <any>{
                        code: val,
                        display: display || String(value)
                    }
                };

                if (system && system !== "") {
                    answer.valueCoding.system = system;
                }

                item.answer.push(answer)
            });
        } else {
            let answer = {
                valueCoding: <any>{
                    code: value,
                    display: display || String(value)
                }
            };

            if (system) {
                answer.valueCoding.system = system;
            }

            item.answer.push(answer);
        }
    }

    // create a clean, valid tree from the questionnaire in the response in response.item
    public static EnsureStructuralHullIntegrity(questionnaire: any, response: any) {
        if (!questionnaire || !response) return response;

        const originalResponse = NitTools.Clone(response);
        response.item = [];

        // create a clean, valid tree from the questionnaire in the response in response.item
        Questionnaire.EnsureStructuredResponse(questionnaire, response);

        const linkIds = Questionnaire.GetAllQuestionnaireItemLinkIds(questionnaire);
        for (const linkId of linkIds) {

            // the originally contained response item, if existend
            const origResponseItem = QuestionnaireResponse.GetResponseItemByLinkId(originalResponse, linkId, false);

            // the questionnaire item
            const questionnaireItem = Questionnaire.GetQuestionnaireItemByLinkId(questionnaire, linkId);

            // if both exist..
            if (questionnaireItem && origResponseItem && questionnaireItem.type !== 'group' && typeof origResponseItem.answer !== 'undefined') {
                // .. get the newly generated item from the response ..
                const freshResponseItem = QuestionnaireResponse.GetResponseItemByLinkId(response, linkId, false);

                if (freshResponseItem) {   // if found, copy the given answer value from the provided reponse
                    freshResponseItem.answer = NitTools.Clone(origResponseItem.answer);
                    if (origResponseItem.extension)
                        freshResponseItem.extension = origResponseItem.extension;
                }
            }
        }

        return response;
    }
}
